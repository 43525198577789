import Vue from 'vue'
// @ts-ignore
import categories  from "../components/Categories";
document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector("#categories");
    const scopeElement = document.getElementById('disclosure-scope')
    const scope = scopeElement.dataset.scope

    const app = new Vue({
        render: h => h(categories, {props: {scope: scope}}),
    }).$mount();
    el.appendChild(app.$el);
});
