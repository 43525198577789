<template>
  <section class="category">
    <h2 class="title font-heading en is-4">商品カテゴリー</h2>
    <div class="contents">
      <dl class="list-table-link">
        <template v-for="category in categories">
        <dt class="font-heading ja is-6">
          <a :href="'/products?category=' + category.id">
            <span class="icon"><i :class="category.icon || 'far fa-box-alt'"></i></span><span>{{ category.title }}</span>
          </a>
        </dt>
        <dd>
          <ul class="list-inline">
            <li v-for="subCategory in category.children"><a class="small-btn" :href="'/products?category=' + subCategory.id">{{ subCategory.title }}</a></li>
          </ul>
        </dd>
        </template>
      </dl>
    </div>
  </section>

</template>

<script>
import axios from "axios";
export default {
  name: "categories",
  props: ['scope'],
  data() {
    return {
      categories: []
    }
  },
  async mounted() {

    const response = await axios('/categories.json?scope=' + this.scope)
    this.categories = response.data.categories


  }
}
</script>

<style scoped>

</style>
